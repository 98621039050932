import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig =  {
  apiKey: "AIzaSyDbWyNJtNx5ELhKl7tP61nS6kVD1vu0Aso",
  authDomain: "ksolutions-8aed8.firebaseapp.com",
  projectId: "ksolutions-8aed8",
  storageBucket: "ksolutions-8aed8.appspot.com",
  messagingSenderId: "1057144894534",
  appId: "1:1057144894534:web:35f9341333729231c8c647",
  measurementId: "G-CSBRVC1MZP"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.database();
const auth = firebase.auth();

export { db, auth };
