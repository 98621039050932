import React, { Fragment } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Flex } from "reflexbox";
import { Card, CardMedia } from '@material-ui/core';
import OurPartnersHeader from "./PartnerHeader";
import awslogo from '../../images/awslogo.png';
import ciscologo from '../../images/ciscologo.png';
import fortinetlogo from '../../images/fortinetlogo.png';
import gcplogo from '../../images/gcplogo.jpg';
import microsoftlogo from '../../images/microsoftlogo.png';
import symanteclogo from '../../images/symanteclogo.png';

import Veeamlogo from '../../images/Veeamlogo.png';
import veritaslogo from '../../images/veritaslogo.png';
import acronislogo from '../../images/acronislogo.png';

const InProgress = () => {
  const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      margin: '2px 2px 2px 2px',
      flexDirection: "column",
      alignItems: "center",
      height: "800px",
      flexWrap: "wrap"
    },
    media: {
      height: 140,
      width: 240,
      margin: '2px 2px 2px 2px',
      objectFit: 'cover'
    }
  }));
  const classes = useStyles();
  return (
    <Fragment>
     
      <Container component="main" style={{ "background-color": "aliceblue" }}>
      <Flex flexDirection="column" style={{ marginTop: "50px" }}>
      <OurPartnersHeader></OurPartnersHeader>
      </Flex>
        <CssBaseline />
        <div className={classes.paper}>
          
          <Flex flexDirection="column" style={{ marginTop: "150px" }}>
            <Flex flexDirection="row" flexWrap="wrap" justifyContent="left">
            <Card>
              <CardMedia
                component="img"
                className={classes.media}
               
                image={awslogo}
              />
            </Card>
            </Flex>

            <Flex flexDirection="row" flexWrap="wrap" style={{ marginTop: "50px" }} justifyContent="left">
              <Card>
                <CardMedia
                  component="img"
                  className={classes.media}
                  image={gcplogo}
                />
              </Card>
            </Flex>

            <Flex flexDirection="row" flexWrap="wrap" style={{ marginTop: "50px" }} justifyContent="left">
              <Card>
                <CardMedia
                  component="img"
                  className={classes.media}
                  image={microsoftlogo}
                />
              </Card>
            </Flex>

          </Flex>

          <Flex flexDirection="column" style={{ marginTop: "150px" }}>
            <Flex flexDirection="row" flexWrap="wrap" justifyContent="left">
            <Card>
              <CardMedia
                component="img"
                className={classes.media}
               
                image={fortinetlogo}
              />
            </Card>
            </Flex>

            <Flex flexDirection="row" flexWrap="wrap" style={{ marginTop: "50px" }} justifyContent="left">
              <Card>
                <CardMedia
                  component="img"
                  className={classes.media}
                  image={ciscologo}
                />
              </Card>
            </Flex>

            <Flex flexDirection="row" flexWrap="wrap" style={{ marginTop: "50px" }} justifyContent="left">
              <Card>
                <CardMedia
                  component="img"
                  className={classes.media}
                  image={symanteclogo}
                />
              </Card>
            </Flex>

          </Flex>


          <Flex flexDirection="column" style={{ marginTop: "150px" }}>
            <Flex flexDirection="row" flexWrap="wrap" justifyContent="left">
            <Card>
              <CardMedia
                component="img"
                className={classes.media}
               
                image={Veeamlogo}
              />
            </Card>
            </Flex>

            <Flex flexDirection="row" flexWrap="wrap" style={{ marginTop: "50px" }} justifyContent="left">
              <Card>
                <CardMedia
                  component="img"
                  className={classes.media}
                  image={veritaslogo}
                />
              </Card>
            </Flex>

            <Flex flexDirection="row" flexWrap="wrap" style={{ marginTop: "50px" }} justifyContent="left">
              <Card>
                <CardMedia
                  component="img"
                  className={classes.media}
                  image={acronislogo}
                />
              </Card>
            </Flex>

          </Flex>
        </div>
      </Container>
    </Fragment>
  );
};
export default InProgress;
