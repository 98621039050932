import React, { useEffect } from "react";
import { Flex, Box } from "reflexbox";
import OutlinedCard from "../Common/cards/";
import siteJson from "../constants";
import "./Home.css";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import CallIcon from "@material-ui/icons/Call";
import FreeQuotes from "../FreeQuotes/FreeQuotes";
import gear from "../../images/gear.png";
import instagram from "../../images/instagram.png";
import homeimage from "../../images/homeimage.png";
import Carousel from "react-material-ui-carousel";
import Subservices from "../SubServices/SubServices";
import CloseIcon from "@material-ui/icons/Close";
import { useIdleTimer } from "react-idle-timer";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
const Home = ({ services, quotes, history }) => {
  
  const SESSION_IDEL_SEC = 20;
  const handleOnIdle = event => {
    if (!pageLoaded) {
      setpageLoaded(true);
      setPopUp(true);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * SESSION_IDEL_SEC,
    onIdle: handleOnIdle,
    debounce: 500
  });
  useEffect(() => {
    if (services) {
      window.scrollTo(0, 700);
    }
    if (quotes) {
      window.scrollTo(0, 2300);
    }
  }, [services, quotes]);

  const { home } = siteJson;
  const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }
  }));
  const [pageLoaded, setpageLoaded] = React.useState(false);
  const [popUp, setPopUp] = React.useState(false);
  const [img, setImg] = React.useState("noImg");
  const [open, setOpen] = React.useState("false");
  const [toggle, setToggle] = React.useState("noImgt");
  const handleClickOpen = (image, row) => {
    if (image !== toggle) {
      setOpen(row);
      setImg(image);
      setToggle(image);
    } else {
      setOpen("false");
      setToggle("noImgt");
    }
  };

  const handleClose = event => {
    setOpen("false");
    setToggle("noImgt");
  };
  const handleClosePop = event => {
    setPopUp(false);
  };
  const classes = useStyles();
  function Item(props) {
    return (
      <Paper style={{ "background-color": "aliceblue" }}>{props.item}</Paper>
    );
  }
  var items = [];
  items.push(
    <Flex
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="center"
      style={{ "background-color": "white" }}
    >
      <Box width={[1, 1 / 2]} p={2} style={{ marginTop: "50px" }}>
        <div className={classes.paper}>
          <div
            style={{
              "margin-top": "100px",
              width: "450px",
              "font-size": "30px",
              font: "Hoefler Text",
              opacity: 1
            }}
          >
            We are an outcome-focused consulting firm, helping companies harness
            the power of
            <div
              style={{
                color: "white",
                "background-color": "#014F86",
                "text-align": "center",
                width: "max-content",
                paddingLeft: "10px",
                paddingRight: "10px"
              }}
            >
              digital transformation.
            </div>
          </div>
        </div>
      </Box>
      <Box width={[1, 1 / 2]} p={2} style={{ marginTop: "50px" }}>
        <img
          src={homeimage}
          style={{
            height: "520px",
            width: "600px",
            verticalAlign: "text-bottom"
          }}
          flexWrap="wrap"
          justifyContent="center"
          alt={homeimage}
        />
      </Box>
    </Flex>
  );
  items.push(
    <Flex
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="center"
      style={{ "background-color": "white" }}
    >
      <Box width={[1, 1 / 2]} p={2} style={{ marginTop: "50px" }}>
        <div className={classes.paper}>
          <div
            style={{
              "margin-top": "100px",
              width: "450px",
              "font-size": "30px",
              font: "Hoefler Text",
              opacity: 1
            }}
          >
            We are an outcome-focused consulting firm, helping companies harness
            the power of
            <div
              style={{
                color: "white",
                "background-color": "#014F86",
                "text-align": "center",
                width: "max-content",
                paddingLeft: "10px",
                paddingRight: "10px"
              }}
            >
              digital transformation.
            </div>
          </div>
        </div>
      </Box>
      <Box width={[1, 1 / 2]} p={2} style={{ marginTop: "50px" }}>
        <img
          src={homeimage}
          style={{
            height: "520px",
            width: "600px",
            "vertical-align": "text-bottom"
          }}
          flexWrap="wrap"
          justifyContent="center"
          alt={homeimage}
        />
      </Box>
    </Flex>
  );
  var testimonials = [];
  testimonials.push(
    <Container component="main" width="100%">
      <div className={classes.paper} style={{ marginTop: "0px" }}>
        <div style={{ "text-transform": "capitalize", marginTop: "20px" }}>
          <b> Software Engineer</b>
        </div>
        <div style={{ marginTop: "20px", maxWidth: "700px" }}>
          "Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum".
        </div>
        <img
          src={instagram}
          style={{
            height: "20px",
            width: "20px",
            "vertical-align": "text-bottom",
            marginTop: "20px",
            marginBottom: "20px"
          }}
          alt={instagram}
        />
      </div>
    </Container>
  );
  testimonials.push(
    <Container component="main" width="100%">
      <div className={classes.paper} style={{ marginTop: "0px" }}>
        <div style={{ "text-transform": "capitalize", marginTop: "20px" }}>
          <b> Software Engineer</b>
        </div>
        <div style={{ marginTop: "20px", maxWidth: "700px" }}>
          "Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum".
        </div>
        <img
          src={instagram}
          style={{
            height: "20px",
            width: "20px",
            "vertical-align": "text-bottom",
            marginTop: "20px",
            marginBottom: "20px"
          }}
          alt={instagram}
        />
      </div>
    </Container>
  );
  return (
    <div style={{ "background-color": "aliceblue" }}>
      <Carousel>
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>

      <Box style={{ "background-color": "aliceblue" }}>
        <div className={classes.paper} style={{ "margin-top": "30px" }}>
          <Typography
            component="h1"
            variant="h5"
            style={{
              color: "#014F86",
              "font-weight": "700",
              "margin-top": "10px"
            }}
          >
            Services
          </Typography>
          <Typography
            component="h1"
            variant="h5"
            style={{
              color: "blue",
              "font-weight": "700"
            }}
          >
            -&nbsp;-&nbsp;
            <img
              src={gear}
              style={{
                height: "20px",
                "vertical-align": "text-bottom"
              }}
              alt={gear}
            />
            &nbsp;-&nbsp;-
          </Typography>
        </div>
        <Flex flex-direction="row" flexWrap="wrap" justifyContent="center">
          {home.cardsR1.map(a => (
            <Box
              width={[1, 1 / 3]}
              p={3}
              onClick={() => handleClickOpen(a.imageMessage, "1")}
              className="test"
            >
              {" "}
              <OutlinedCard p={a}> </OutlinedCard>
            </Box>
          ))}
        </Flex>
        {open === "1" && (
          <div style={{ backgroundColor: "white", padding: "20px" }}>
            <Flex width="inherit" flexDirection="row" justifyContent="flex-end">
              {" "}
              <div onClick={handleClose}>
                <CloseIcon />
              </div>
            </Flex>
            <Subservices ServiceName={img} card="cardsR1" />
          </div>
        )}
        <Flex flex-direction="row" flexWrap="wrap" justifyContent="center">
          {home.cardsR2.map(a => (
            <Box
              width={[1, 1 / 3]}
              p={3}
              onClick={() => handleClickOpen(a.imageMessage, "2")}
              className="test"
            >
              <div>
                {" "}
                <OutlinedCard p={a}> </OutlinedCard>
              </div>
            </Box>
          ))}
        </Flex>
        {open === "2" && (
          <div style={{ backgroundColor: "white" }}>
            <Flex width="inherit" flexDirection="row" justifyContent="flex-end">
              {" "}
              <div onClick={handleClose}>
                <CloseIcon />
              </div>
            </Flex>
            <Subservices ServiceName={img} card="cardsR2" />
          </div>
        )}
        <Flex flex-direction="row" flexWrap="wrap" justifyContent="center">
          {home.cardsR3.map(a => (
            <Box
              width={[1, 1 / 3]}
              p={3}
              onClick={() => handleClickOpen(a.imageMessage, "3")}
              className="test"
            >
              <div>
                {" "}
                <OutlinedCard p={a}> </OutlinedCard>
              </div>
            </Box>
          ))}
        </Flex>
        {open === "3" && (
          <div style={{ backgroundColor: "white" }}>
            <Flex width="inherit" flexDirection="row" justifyContent="flex-end">
              {" "}
              <div onClick={handleClose}>
                <CloseIcon />
              </div>
            </Flex>
            <Subservices ServiceName={img} card="cardsR3" />
          </div>
        )}
      </Box>
      <Container
        component="main"
        width="100%"
        style={{ "background-color": "aliceblue" }}
      >
        <CssBaseline />
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            style={{
              color: "#014F86",
              "font-weight": "700",
              "margin-top": "10px"
            }}
          >
            Contact Us
          </Typography>
          <Typography
            component="h1"
            variant="h5"
            style={{
              color: "blue",
              "font-weight": "700"
            }}
          >
            -&nbsp;-&nbsp;
            <img
              src={gear}
              style={{
                height: "20px",
                "vertical-align": "text-bottom"
              }}
              alt={gear}
            />
            &nbsp;-&nbsp;-
          </Typography>
        </div>
        <Grid container spacing={2} style={{ "background-color": "aliceblue" }}>
        <Grid
            item
            xs={12}
            sm={6}
            style={{ height: "600px", "margin-top": "50px" }}
          >
            <FreeQuotes home history={history} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ height: "600px", marginTop: "52px" }}
          >
            <Grid style={{ height: "30%" }}>
              <Box marginLeft="3%">
                <div className={classes.paper} style={{ marginTop: "0px" }}>
                  <Typography
                    component="h1"
                    variant="h5"
                    style={{
                      color: "#014F86",
                      "text-decoration": "underline"
                    }}
                  >
                    {" "}
                    Address{" "}
                  </Typography>
                </div>
                <p> 48 - Sydney Road, Manly, NSW,sydney, 2095 </p>
              </Box>
              <Box marginLeft="3%" marginBottom="10px">
                <div>
                  <CallIcon></CallIcon> &nbsp;+61 416283705
                </div>
                <div>
                  <MailIcon></MailIcon> &nbsp;sales@k-solutions.dev
                </div>
              </Box>
            </Grid>
            <Grid style={{ height: "45%" }}>
              <iframe
                height="100%"
                width="100%"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.602418497896!2d151.28370521495546!3d-33.796763780676116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ab08b293817f%3A0xcb25cda4d6cbd56e!2s48%20Sydney%20Rd%2C%20Manly%20NSW%202095%2C%20Australia!5e0!3m2!1sen!2ssg!4v1607757969753!5m2!1sen!2ssg"
                frameBorder="0"
                aria-hidden="false"
                title="maps"
              ></iframe>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Dialog open={popUp}>
        <ClickAwayListener onClickAway={handleClosePop}>
          <DialogContent
            style={{ overflow: "hidden", "background-color": "aliceblue" }}
          >
            <Flex width="inherit" flexDirection="row" justifyContent="flex-end">
              <div onClick={handleClosePop}>
                <CloseIcon />
              </div>
            </Flex>
            <FreeQuotes
              popUp={true}
              location={window.location}
              history={history}
            />
          </DialogContent>
        </ClickAwayListener>
      </Dialog>
    </div>
  );
};

export default Home;
