import React, { Fragment } from "react";
import { Button } from "@material-ui/core";
import { Flex } from "reflexbox";
import renderTextareaAutosize from "../Common/TextArea";
import renderTextField from "../Common/TextField";
import { Field, reduxForm } from "redux-form";
import Grid from "@material-ui/core/Grid";
import renderDropDown from "../Common/DropDown";
import TextField from "@material-ui/core/TextField";
import siteJson from "../constants";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { db } from "../../firebase";
import quotesValidation from "../Validation/quotesValidation";

const EmailSend = window.Email;
const FreeQuotes = ({
  home,
  popUp,
  history,
  location,
  handleSubmit,
  reset,
  errors
}) => {
  const useStyles = makeStyles(theme => ({
    paper: {
      marginTop: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "aliceblue"
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(3)
    },
    submit: {
      margin: theme.spacing(3, 0, 2)
    }
  }));
  const [open, setOpen] = React.useState(false);

  const firebasePush = details => {
    if (db && details) {
      db.ref("details")
        .push()
        .set({ ...details });
    }
  };
  const sendEmail = async (businessEmail, categories, messageText) => {
    if (businessEmail) {
      const message = await EmailSend.send({
        Host: "smtp.gmail.com",
        Username: "ksolutionstest@gmail.com",
        Password: "Qwerty@12345",
        To: businessEmail,
        From: "ksolutionstest@gmail.com",
        Subject: `Enquiry On: ${categories || ""}`,
        Body: `Hi,
         The below customer has a ${messageText || ""}
        `
      });
      return message;
    }
    return "no email";
  };
  const sendBusinessEmail = async (
    businessEmail,
    categories,
    messageText,
    name
  ) => {
    if (businessEmail) {
      const message = await EmailSend.send({
        Host: "smtp.gmail.com",
        Username: "ksolutionstest@gmail.com",
        Password: "Qwerty@12345",
        To: "ksolutionstest@gmail.com",
        From: "ksolutionstest@gmail.com",
        Subject: `Enquiry On: ${categories || ""}`,
        Body: `Hi Admin ,
         The below customer ${name} has a ${messageText || ""}
        `
      });
      return message;
    }
    return "no email";
  };
  const submit = async (e) => {
   
    // //hit api from here
    firebasePush(e);
    const message = await sendEmail(e.businessEmail, e.categories, e.message);
    const businessmessage = await sendBusinessEmail(e.businessEmail, e.categories, e.message);
    let status = 200;
    if (message === "OK" && businessmessage) {
      status = 200;
    } else {
      status = 500;
    }

    const x = Promise.resolve({
      status,
    });
    await x.then((response) => {
      if (popUp) {
        history.push(location);
      } else {
        setOpen(response.status === 200 ? "success" : "failure");
      }
    });
  };
  const handleClose = () => {
    setOpen(false);
    reset();
  };
  const { categories } = siteJson;
  const classes = useStyles();
  let props = {};
  if (home) {
    props = {
      maxWidth: "500px",
      paddingLeft: "0px !important",
      paddingRright: "0px !important"
    };
  } else {
    // eslint-disable-next-line no-unused-vars
    props = {
      maxWidth: "sm"
    };
  }
  return (
    <Fragment>
      <Container component="main" style={{ "background-color": "aliceblue" }}>
        <Flex
          justifyContent="center"
          style={{
            "background-color": "aliceblue",
            height: "-webkit-fill-available",
            width: "-webkit-fill-available"
          }}
        >
          <div
            style={{
              "background-color": "aliceblue",
              height: "500px",
              width: "600px"
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              style={{
                color: "#014F86",
                "text-decoration": "underline",
                marginBottom: "20px",
                textAlign: "center"
              }}
            >
              Get Free Quote
            </Typography>
            <form onSubmit={handleSubmit(submit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    required
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    component={renderTextField}
                    type="text"
                    label="Your Name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    required
                    variant="outlined"
                    fullWidth
                    name="businessEmail"
                    component={renderTextField}
                    type="text"
                    label="Business Email"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    required
                    variant="outlined"
                    fullWidth
                    name="telephoneNumber"
                    component={renderTextField}
                    type="text"
                    label="Mobile Number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    required
                    variant="outlined"
                    fullWidth
                    name="companyName"
                    component={renderTextField}
                    type="text"
                    label="Company Name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    required
                    variant="outlined"
                    fullWidth
                    name="categories"
                    id="Categories"
                    component={renderDropDown}
                    type="text"
                    label="Categories*"
                    options={categories}
                    getOptionLabel={option => option.title}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Categories"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    style={{
                      width: "-webkit-fill-available",
                      "background-color": "aliceblue"
                    }}
                    name="message"
                    component={renderTextareaAutosize}
                    type="text"
                    label="Message"
                    rows={8}
                    rowsMin={7}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
              <Dialog
                maxWidth="md"
                open={open === "success"}
                aria-labelledby="max-width-dialog-title"
                style={{
                  "background-color": "#E7F3F8"
                }}
              >
                <div style={{ "margin-top": "0px", width: "600px" }}>
                  <div
                    className={classes.paper}
                    style={{
                      "margin-top": "0px"
                    }}
                  >
                    <div
                      style={{
                        "background-color": "aliceblue",
                        "text-align": "center",
                        width: "100%"
                      }}
                    >
                      {" "}
                      <CheckCircleIcon
                        style={{
                          "font-size": "100px",
                          color: "#6DC17D",
                          "margin-top": "20px",
                          "margin-bottom": "20px"
                        }}
                      ></CheckCircleIcon>
                    </div>

                    <Typography
                      component="h1"
                      variant="h5"
                      style={{
                        "font-weight": "300",
                        marginBottom: "10px",
                        marginTop: "30px"
                      }}
                    >
                      <b> Submit Successfull</b>
                    </Typography>
                    <div
                      style={{
                        "font-weight": "100",
                        marginBottom: "10px"
                      }}
                    >
                      One of our service provider will contact you soon.
                    </div>
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      style={{
                        marginBottom: "10px",
                        "background-color": "#D64949",
                        color: "white",
                        "text-transform": "capitalize"
                      }}
                    >
                      <b>close</b>
                    </Button>
                  </div>
                </div>
              </Dialog>
              <Dialog
                maxWidth="xs"
                open={open === "failure"}
                aria-labelledby="max-width-dialog-title"
                style={{
                  " background-color": " #E7F3F8"
                }}
              >
                <DialogContent>
                  <Container component="main" width="70%">
                    <div className={classes.paper}>
                      <div
                        style={{
                          "font-size": "70px",
                          opacity: 1
                        }}
                      >
                        <b> 404</b>
                      </div>
                      <Typography
                        component="h1"
                        variant="h5"
                        style={{
                          "font-weight": "300xp",
                          marginBottom: "20px",
                          opacity: 1
                        }}
                      >
                        <b> Page Not Found</b>
                      </Typography>
                      <div
                        style={{
                          "font-weight": "100",
                          marginTop: "20px",
                          marginBottom: "30px",
                          "text-align": "center",
                          width: "100%",
                          opacity: 1
                        }}
                      >
                        The link you clicked may be broken or the page may have
                        been removed or renamed.
                      </div>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        style={{
                          marginBottom: "10px",
                          "background-color": "#D64949",
                          color: "white",
                          "text-transform": "capitalize"
                        }}
                      >
                        <b>close</b>
                      </Button>
                    </div>
                  </Container>
                </DialogContent>
              </Dialog>
            </form>
          </div>
        </Flex>
      </Container>
    </Fragment>
  );
};
export default reduxForm({
  form: "editFreeQuotes",
  validate: quotesValidation
})(FreeQuotes);
