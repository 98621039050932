import TextField from "@material-ui/core/TextField";

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid && error}
    helperText={touched ? error : ""}
    {...input}
    {...custom}
  />
);

export default renderTextField;