import { TextareaAutosize } from "@material-ui/core";

const renderTextareaAutosize = ({
  label,
  input,
  style,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <TextareaAutosize
    label={label}
    placeholder={label}
    style={style}
    error={touched && invalid}
    helpertext={touched ? error : ""}
    {...input}
    {...custom}
  />
);

export default renderTextareaAutosize;
