import * as React from "react";
import { Flex, Box } from "reflexbox";
import OutlinedCard from "../Common/cards";
import siteJson from "../constants";
import Typography from "@material-ui/core/Typography";
const SubServices = ({ ServiceName, card }) => {
  const { home } = siteJson;
  const obj = home[card].filter(x => x.imageMessage === ServiceName);
  const row = [];
  for (let i = 0; i < 4; i++) {
    row.push(obj[0]);
  }
  return (
    <div>
      <Flex flexDirection="row" justifyContent="center">
        <Typography
          component="h1"
          variant="h5"
          style={{
            color: "blue",
            "font-weight": "200",
            "text-decoration": "underline",

            "font-size": "large"
          }}
        >
          {ServiceName}
        </Typography>
      </Flex>
      <Box marginTop="3%">
        <Flex flex-direction="row" flexWrap="wrap" justifyContent="center">
          {row.map(a => (
            <Box width={[1, 1 / 4]} p={4} className="test">
              <OutlinedCard p={a}> </OutlinedCard>
            </Box>
          ))}
        </Flex>
        <Flex flex-direction="row" flexWrap="wrap" justifyContent="center">
          {row.map(a => (
            <Box width={[1, 1 / 4]} p={4} className="test">
              <OutlinedCard p={a}> </OutlinedCard>
            </Box>
          ))}
        </Flex>
      </Box>
    </div>
  );
};

export default SubServices;
