import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import klogonew from "../../images/2.png";
import MenuIcon from "@material-ui/icons/Menu";
import { Flex } from "reflexbox";
import MenuItem from "@material-ui/core/MenuItem";
import "./Header.css";
import { MenuList } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#12276E"
  },
  title: {
    flexGrow: 1
  },
  iconSize: {
    fontSize: "60px"
  },
  headerImage: {
    height: "40px",
    width: "100px",
    "vertical-align": "text-bottom",
    "margin:left": "30px"
  },
  homeButton: {
    padding: "5px",
    color:
      window.location.pathname.includes("home") ||
      window.location.pathname.includes("")
        ? "white"
        : "lightgray",
    backgroundColor: "#12276E"
  },
  serviceButton: {
    padding: "5px",
    color: window.location.pathname.includes("services")
      ? "white"
      : "lightgray",
    backgroundColor: "#12276E"
  },
  partnerButton: {
    padding: "5px",

    color: window.location.pathname.includes("partner") ? "white" : "lightgray",
    backgroundColor: "#12276E"
  },
  getQuotesButton: {
    padding: "5px",
    color: window.location.pathname.includes("quotes") ? "white" : "lightgray",
    backgroundColor: "#12276E"
  },
  tButtonStyle: {
    "text-transform": "capitalize",
    "font-size": "large",
    "padding-left": "40px",
    "padding-right": "40px"
  },
  mButtonStyle: {
    "text-transform": "capitalize",
    "font-size": "large"
  },
  header: {
    "background-color": "#12276E !important"
  }
}));

export default function Header({ history }) {
  const classes = useStyles();
  const [openMenu, setMenuOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openTheMenu = event => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };
  const handleClose = () => {
    setMenuOpen(false);
  };
  const buttonList = [
    { label: "Services", href: "/services", className: classes.serviceButton },
    { label: "Partners", href: "/partner", className: classes.partnerButton },
    { label: "Get Quotes", href: "/quotes", className: classes.getQuotesButton }
  ];
  const menubutton = b => {
    return (
      <MenuItem>
        <Button className={b.className} href={b.href}>
          <div class="test">
            <Typography className={classes.mButtonStyle}>{b.label}</Typography>
          </div>
        </Button>
      </MenuItem>
    );
  };
  const toolbutton = b => {
    return (
      <Button color="inherit" className={b.className} href={b.href}>
        <div class="test">
          <Typography className={classes.tButtonStyle}>{b.label}</Typography>
        </div>
      </Button>
    );
  };
  return (
    <div className={classes.root}>
      <Flex
        className="Hamburger"
        flexDirection="row"
        justifyContent="space-between"
      >
        {/* <img src={klogo} className={classes.headerImage} href="/home" alt="" /> */}
        <MenuIcon
          ref={anchorEl}
          onClick={openTheMenu}
          className={classes.iconSize}
        ></MenuIcon>
        <Popper
          open={openMenu}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          className="Hamburger"
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                style={{
                  backgroundColor: "#12276E"
                }}
              >
                {buttonList.map(b => menubutton(b))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </Flex>
      <AppBar className="Header" position="static">
        <Toolbar className={classes.header}>
          <Typography variant="h6" className={classes.title}>
            <a href="/home">
              <img
                src={klogonew}
                className={classes.headerImage}
                href="/home"
                alt=""
              />
            </a>
          </Typography>
          {buttonList.map(b => toolbutton(b))}
        </Toolbar>
      </AppBar>
    </div>
  );
}
