import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import customApps from "../../../images/customApps.png";
import artificialintelligence from "../../../images/artificial-intelligence.png";
import blockchain from "../../../images/blockchain.png";
import cloudapps from "../../../images/cloudapps.png";
import cybersecurity from "../../../images/cyber-security.png";
import analytics from "../../../images/analytics.png";
import bigdata from "../../../images/bigdata.png";
import managedServices from "../../../images/managedServices.png";
import skills from "../../../images/skills.png";
import talentServices from "../../../images/talentServices.svg";
import { Flex } from "reflexbox";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    height: "300px",
    padding: "0px !important",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    marginTop: "10px",
    marginLeft: "10px",
    color: "#014F86",
    "text-decoration": "underline",
    fontSize: "20px",
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    height: "190px",
    width: "100%",
    marginTop:"10px",
    objectFit: "contain",
  },
});

const OutlinedCard = (p) => {
  const classes = useStyles();
  // const bull = <span className={classes.bullet}>•</span>;
  const images = [];
  images.push({ customApps: customApps });
  images.push({ artificialintelligence: artificialintelligence });
  images.push({ blockchain: blockchain });
  images.push({ cloudApps: cloudapps });
  images.push({ cybersecurity: cybersecurity });
  images.push({ analytics: analytics });
  images.push({ bigdata: bigdata });
  images.push({ managedServices: managedServices });
  images.push({ skills: skills });
  images.push({ talentServices: talentServices });

  const { imageName, imageMessage, imageDescription } = p.p;
  return (
    <Card className={classes.root} variant="outlined">
      <CardMedia
        component="img"
        className={classes.media}
        alt={imageMessage}
        image={Object.values(
          images.find((p) => Object.keys(p)[0] === imageName)
        )}
        title="Contemplative Reptile"
      />
      <Flex flexDirection="row" justifyContent="center">
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {imageMessage}
        </Typography>
      </Flex>
      <Flex
        style={{
          flexDirection: "column",
          height: "100px",
          marginLeft: "10px",
          alignItems: "center",
        }}
      >
        <Typography variant="body2" component="p" style={{ height: "60px" }}>
          {imageDescription}
          <br />
        </Typography>
        <Flex flexDirection="row" justifyContent="center">
          {" "}
          <Button
            style={{ color: "#00D4D8", "text-transform": "capitalize" }}
            size="small"
          >
            Read More
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
};

export default OutlinedCard;
