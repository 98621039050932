import Autocomplete from "@material-ui/lab/Autocomplete";

const renderDropDown = ({
  label,
  input,
  id,
  options,
  getOptionLabel,
  style,
  renderInput,
  meta: { touched, invalid, error },
  ...custom
}) => (
  <Autocomplete
    id={id}
    options={options}
    getOptionLabel={getOptionLabel}
    style={style}
    renderInput={renderInput}
    getOptionSelected={(option, value) => {
      input.onChange((value || {}).title);
    }}
  />
);

export default renderDropDown;
