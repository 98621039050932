import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  header: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '20px',
    textAlign: 'center',
  },
});

export default function OurPartnersHeader() {
  const classes = useStyles();

  return (
    <header className={classes.header}>
      <Typography variant="h5">Our Partners</Typography>
    </header>
  );
}